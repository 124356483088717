import React from "react"
import debounce from "lodash/debounce"
import classNames from "classnames"
import PostCard from "../PostCard/PostCard"
// import { get } from '../../utilities/get.js';
import { UserDataContext } from "../../context/UserDataContext"
import "./main.scss"
import { get } from "../../utilities/get"

const Main = () => {
  const [userData] = React.useContext(UserDataContext)
  //
  const [isMessageReveled, setMessageReveled] = React.useState(false)
  // TODO: Add logic reporting full scroll event
  // const [isBottomReached, setBottomReached] = React.useState(false)
  const message = React.useRef()

  // TODO: This works, if the ID is not dynamic. So maybe it need to be async with a loader in the future.
  // TODO: We should also abstract the first segment f the URL so it work locally and on the remote server with editing.
  /*  React.useEffect(() => {
    // get data from GitHub api
    // fetch(`https://api.github.com/repos/gatsbyjs/gatsby`)
    fetch(`http://christmas-2001.gd:8888/static/log/FirstName.php?u=${userData.id}`)
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        // setStarsCount(resultData.stargazers_count)
        console.log(resultData)
      }) // set data for the number of stars
  }, []) */

  React.useLayoutEffect(() => {
    const calcMessageReveal = () => {
      const { pageYOffset } = window
      const pageInnerHeight = window.innerHeight
      const messageYOffset = message.current.getBoundingClientRect().top
      // console.log('window.pageYOffset: ' + window.pageYOffset)
      // console.log('window.innerHeight: ' + window.innerHeight)

      if (messageYOffset < pageYOffset + pageInnerHeight) {
        setMessageReveled(true)
      } else {
        setMessageReveled(false)
      }
    }

    const scrollEventDebouncer = debounce(() => {
      calcMessageReveal()
    }, 100)

    if (typeof window !== "undefined") {
      window.addEventListener("load", calcMessageReveal())
      window.addEventListener("scroll", scrollEventDebouncer)
      window.addEventListener("resize", scrollEventDebouncer)
    }
    // Remove listener (like componentWillUnmount)
    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("load", calcMessageReveal())
        window.removeEventListener("scroll", scrollEventDebouncer)
        window.removeEventListener("resize", scrollEventDebouncer)
      }
    }
  }, [])

  const coraCookieClick = () => {
    get(`static/log/LogEvent.php?u=${userData.id}&e=cora cookie clicked`)
  }
  const lilyCookieClick = () => {
    get(`static/log/LogEvent.php?u=${userData.id}&e=lily cookie clicked`)
  }
  return (
    <main className="Main">
      <div className="Main-postCard">
        <div className="Main-postCardInner">
          <PostCard />
        </div>
      </div>
      <div className="Main-message">
        <div
          ref={message}
          className={classNames("Main-messageInner", {
            "is-visible": isMessageReveled === true,
          })}
        >
          <p>Hello again! 👋</p>

          <p>First off… sorry to be late with our card this year.  Maybe the fact that it&apos;s digital gives us a false lack of urgency.  Suddenly, before we know it - Christmas was yesterday.  Oh well.</p>

            <p>Regarding 2023, we&apos;re pleased to say that everyone is in relatively good health and spirits save for the odd cold and such. The kiddos both started at new schools this fall with Cora (11) kicking off 6th grade at Hopkinton Middle School, and Lily (9) starting 4th grade at Hopkins Elementary.</p>

              <p>Cora seems to infuse her artistic, industrious nature into each and every homework assignment.  When she isn&apos;t busy with her studies she can be found bumping a volleyball 🏐 around the house, as she continues to play with friends at CMASS.  And of course she remains happy to{" "}
                <a
                    href="https://digitalcookie.girlscouts.org/scout/cora941460"
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => coraCookieClick()}
                >take your cookie order🍪
                  {" "}</a>
                with her Girls Scout troop. We&apos;ll deliver for free if you live nearby.</p>

                <p>Meanwhile Lily is maturing into a force of nature – keeping herself busy with activities she enjoys.  When she isn&apos;t playing soccer with her new team, the Revs Academy, she can be found playing basketball, doing gymnastics, cooking, interior decorating, doing yoga or lately – P90X.  A run-on sentence has never been more apt. 😅</p>

                  <p>Did I mention they both took up skiing?</p>

                    <p>We grown-ups are doing well too.  Jenna started a service design role with Bose and enjoys a side of yoga when the opportunity presents itself. John continues to work as a UX designer when he&apos;s not mowing the lawn or occasionally playing	 volleyball with the rec department.</p>

                      <p>So 2023 has been another busy, eventful year.  We feel blessed and wish you and yours love and peace in &apos;24.</p>
                        <p>- John, Jenna, Cora, Lily and Finn 🐾
                        <br/>69 W Elm St, Hopkinton MA 01748</p>
        </div>
      </div>
    </main>
  )
}

export default Main
